<template>
  <div class="toast-list">
    <div v-for="toast in toasts" class="toast" :class="[toast.type]">
      <div class="flex gap-2">
        <icon
          v-if="toast.type === 'error'"
          width="24"
          icon="heroicons:exclamation-circle-20-solid"
        ></icon>
        <icon
          v-if="toast.type === 'success'"
          width="24"
          icon="heroicons:check-circle-20-solid"
        ></icon>
        <icon
          v-if="toast.type === 'info'"
          width="24"
          icon="heroicons:information-circle-20-solid"
        ></icon>
        <p>{{ toast.message }}</p>
      </div>

      <icon
        icon="heroicons-solid:x"
        width="24"
        color="white"
        @click="removeToast(toast.id!)"
        class="cursor-pointer"
      ></icon>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToastStore } from '@/stores/toast.store'
const { toasts, removeToast } = useToastStore()
</script>

<style scoped>
.toast-list {
  @apply fixed top-0 right-0 z-[10000] w-full flex flex-col-reverse items-center gap-[15px] mt-[60px] lg:mt-[110px];
}

.toast {
  @apply relative px-4 text-white bg-gray-500 rounded-[6px] shadow-md flex justify-between items-center lg:w-[880px] lg:h-[64px] w-[343px] h-[60px];
  animation: fadeInUp 0.3s;
}

p {
  @apply text-white;
}

.toast.info {
  @apply bg-blue-100;
}
.toast.success {
  @apply bg-[#00A462];
}

.toast.error {
  @apply bg-red-100;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
</style>
