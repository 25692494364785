import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import registerGeneralComponents from './plugins/general-components'

import App from './App.vue'
import router from './router'
import VueJsTour from '@globalhive/vuejs-tour'
import '@globalhive/vuejs-tour/dist/style.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueJsTour)
registerGeneralComponents(app)

app.mount('#app')
