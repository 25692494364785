<template>
  <Icon :icon="icon" :color="color" :width="width ? width+'px' : '15px'"></Icon>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'

interface Props {
  icon: string,
  color?: string,
  width?: string
}

const props = defineProps<Props>()
props.icon
props.color
props.width
</script>