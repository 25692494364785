<template>
  <div class="w-full">
    <Header v-if="!router?.currentRoute?.value?.meta?.hideHeaderFooter"/>
    <Toast />

    <main class="mt-[55px] lg:mt-[90px]">
      <RouterView />
    </main>

    <Footer v-if="!router?.currentRoute?.value?.meta?.hideHeaderFooter"/>
  </div>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import router from '@/router'
import Header from '@/components/general/Header.vue'
import Footer from '@/components/general/Footer.vue'
import Toast from '@/components/Toast.vue'

console.log(router);

if (router?.currentRoute?.value?.meta?.hideHeaderFooter) {
  console.log(router?.currentRoute?.value?.meta?.hideHeaderFooter);
} else {
  console.log(router?.currentRoute?.value?.meta?.hideHeaderFooter);
}
</script>
