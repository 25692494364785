<template>
  <div class="footer-wrapper">
    <img class="w-[100px]" src="/logo/logo-gray.svg"/>
    <div>
      <p class="PG3 text-gray-400 hidden lg:block">
        株式会社 R2 | 代表取締役 : イ　ヘギ（李　赫） | 横浜市中区元町5-202-1 BIZcomfort 横浜元町 ANNEX 5階
        <br/>
        E-MAIL : contact-r2@remoterobotics.co.jp
      </p>
      <p class="PG4 text-gray-400 lg:hidden">
        株式会社 R2 | 代表取締役 : イ　ヘギ（李　赫） | 横浜市中区元町5-202-1 BIZcomfort 横浜元町 ANNEX 5階
        <br/>
        E-MAIL : contact-r2@remoterobotics.co.jp
      </p>
    </div>
    <p class="PG3 text-gray-400 hidden lg:block">ⓒ 2023 R2 All rights reserved</p>
    <p class="PG4 text-gray-400 lg:hidden">ⓒ 2023 R2 All rights reserved</p>
    <p class="PG3 text-gray-400 flex justify-end">v{{ version }}</p>
  </div>
</template>

<script setup lang="ts">
  const version = import.meta.env.VITE_PROJECT_VERSION;
</script>

<style scoped>
.footer-wrapper {
  @apply flex flex-col leading-loose border-t gap-[21px] py-[44px] px-[28px] lg:gap-[30px] lg:px-20 lg:pt-[80px] lg:pb-[30px];
}
</style>